const fadeEnter = {
    beforeMount(el) {
        el.classList.add('fade-enter');
    },
    mounted: (el) => {
        const intersectionObserver = new IntersectionObserver(([{isIntersecting}]) => {
            if (isIntersecting) {
                el.style.animationPlayState = 'running';
                intersectionObserver.disconnect();
            }
        }, {
           rootMargin: '0% 0% -20% 0%',
        });

        intersectionObserver.observe(el);
    }
};

export default fadeEnter;
